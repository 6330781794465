




















import { Component, Vue } from 'vue-property-decorator'
import { mapState } from 'vuex'

import { formatDateDistanceStrict } from '@/i18n'
import Modal from '@/components/Modal.vue'
import sessionMonitor from '@/util/sessionMonitor'

@Component({
  components: {
    Modal,
  },
  computed: {
    ...mapState(['showSessionExpirationModal']),
  },
})
export default class SessionExpirationModal extends Vue {
  now: number = Date.now()
  intervalId: number | null = null

  showSessionExpirationModal!: boolean

  onShown() {
    if (this.intervalId) return

    this.intervalId = window.setInterval(() => {
      this.now = Date.now()
    }, 1000)
  }

  get formattedTimeToExpire() {
    return formatDateDistanceStrict(new Date(this.now), new Date(sessionMonitor.expiry as number), {
      unit: 'm',
    })
  }

  async refreshSession() {
    await this.$store.dispatch('startSession')

    this.stopUpdatingCurrentTime()

    this.$store.commit('hideSessionExpirationModal')
  }

  stopUpdatingCurrentTime() {
    if (!this.intervalId) return
    window.clearInterval(this.intervalId)
  }

  beforeDestroy() {
    this.stopUpdatingCurrentTime()
  }
}
